// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* begin com.siemens.splm.client.docmgmt.print */

.aw-docmgmt-printTemplate .aw-docmgmt-configurationTitle {
    float: left;
}

.aw-docmgmt-createprint {
    position: relative;
}

.aw-docmgmt-printtemplatePanel {
    height: inherit;
}

.aw-docmgmt-printtemplatePanel .aw-docmgmt-printTemplate {
    height: inherit;
}

.aw-docmgmt-printtemplatePanel .aw-docmgmt-printTemplate .aw-docmgmt-layoutDocuments {
    margin-top: 15px;
}

.aw-docmgmt-printtemplatePanel .aw-docmgmt-printTemplate .aw-layout-panelFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 10px 10px;
}

.aw-docmgmt-printTemplate .aw-docmgmt-chooseaTemplateTitle {
    display: inline;
}

.aw-docmgmt-batchprintPanel .aw-docmgmt-batchPrint .aw-layout-panelFooter {
    position: absolute;
    bottom: 0;
    left: 0;
}
