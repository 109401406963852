/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

@import 'mixins/mixins';

.aw-scheme-selectIcon {
    width: rem-calc(12);
    height: rem-calc(12);
    display: inline-block;
    margin-right: $margin_small;
}

.aw-partition-ancestor {
    font-weight: bold;
    margin: auto;
}

.searchClass {
    display: flex;
    padding-bottom: rem-calc(5);
}
.aw-partition-filter-panel {
    width: 100%;
}
.aw-partition-filter-panel1 {
    width: 35%;
}
.aw-partition-filter-panel2 {
    width: 60%;
}

.aw-partition-locationTitle {
    padding-left: $padding_small;
    display: inline-table;
}

.aw-partition-mergeBreadcrumbName {
    font-size: $header_fontsize;
    padding-right: $padding_small;
    padding-top: rem-calc(2);
}
