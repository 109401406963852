// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
.aw-pdfjs-pdfPane {
    height: 100%;
}

.aw-pdfjs-pdfViewerIFrameWidget {
    height: 100%;
}

.aw-pdfjs-pdfViewerIFrame {
    height: 100%;
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.aw-pdfjs-statusLabel {
    height: 20px;
}

.aw-pdfjs-pdfPaneViewer {
    height: 100%;
}
