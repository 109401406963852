/*This change is for other tabs */
.afx-medium-background .sw-tabContainer .sw-tab a.sw-tab-title,.aw-layout-subLocation .sw-tabContainer .sw-tab a.sw-tab-title,.aw-layout-popup .sw-tabContainer .sw-tab a.sw-tab-title , .aw-layout-primaryWorkarea .sw-tabContainer .sw-tab a.sw-tab-title, .noty_container_type_error .sw-tabContainer .sw-tab a.sw-tab-title, .noty_container_type_information .sw-tabContainer .sw-tab a.sw-tab-title, .noty_container_type_warning .sw-tabContainer .sw-tab a.sw-tab-title, .afx-content-background .sw-tabContainer .sw-tab a.sw-tab-title, .afx-alt-content-background .sw-tabContainer .sw-tab a.sw-tab-title, .aw-layout-sublocationContainer .sw-tabContainer .sw-tab a.sw-tab-title{
    color: #1e1e1e;
    border-bottom: .2rem solid !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top: 0.01px !important;
    padding: 1.5px 1.5px 1.5px 1.5px !important;
    border-bottom-color: #0f789b !important;
}

/* This change is current selected tab */
.afx-medium-background .sw-tabContainer .sw-tab a.sw-tab-selected,.aw-layout-subLocation .sw-tabContainer .sw-tab a.sw-tab-selected,.aw-layout-popup .sw-tabContainer .sw-tab a.sw-tab-selected , .aw-layout-primaryWorkarea .sw-tabContainer .sw-tab a.sw-tab-selected, .noty_container_type_error .sw-tabContainer .sw-tab a.sw-tab-selected, .noty_container_type_information .sw-tabContainer .sw-tab a.sw-tab-selected, .noty_container_type_warning .sw-tabContainer .sw-tab a.sw-tab-selected, .afx-content-background .sw-tabContainer .sw-tab a.sw-tab-selected, .afx-alt-content-background .sw-tabContainer .sw-tab a.sw-tab-selected, .aw-layout-sublocationContainer .sw-tabContainer .sw-tab a.sw-tab-selected {
    color: #0f789b;
    border-top: .2rem solid !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 0.01px !important;
    padding: 1.5px 1.5px 1.5px 1.5px !important;
}

/* This change for tabs coming after global search */

/* For selected tab*/
.sw-tabContainer .sw-tab a.sw-tab-selected, .sw-tabContainer .sw-tab a.sw-tab-selected:hover
{
    color: #ffb900;
    border-top: .2rem solid !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 0.01px !important;
    padding: 1.5px 1.5px 1.5px 1.5px !important;
} 
/* For other tabs*/
.sw-tabContainer .sw-tab a.sw-tab-title, .sw-tabContainer .sw-tab a.sw-tab-selected:hover{
    color: #ffb900;
    border-bottom: .2rem solid !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top: 0.01px !important;
    padding: 1.5px 1.5px 1.5px 1.5px !important;
    border-bottom-color: #0f789b !important;
}



/* Change the color of Progress Bar */
.aw-layout-progressBarCylon {
    background-image: -webkit-linear-gradient(to right, #2387aa 0px, #2387aa 0px);
    background-image: -moz-linear-gradient(to right, #2387aa 0px, #2387aa 0px);
    background-image: -ms-linear-gradient(to right, #2387aa 0px, #2387aa 0px);
    background-image: linear-gradient(to right, #2387aa 0px, #ff7300 0px) !important;
}

/* Change the color of Asterisk mark */
.aw-layout-subLocation .sw-property.sw-required .sw-property-name:before, .aw-layout-popup .sw-property.sw-required .sw-property-name:before, .aw-layout-primaryWorkarea .sw-property.sw-required .sw-property-name:before, .noty_container_type_error .sw-property.sw-required .sw-property-name:before, .noty_container_type_information .sw-property.sw-required .sw-property-name:before, .noty_container_type_warning .sw-property.sw-required .sw-property-name:before, .afx-content-background .sw-property.sw-required .sw-property-name:before, .afx-alt-content-background .sw-property.sw-required .sw-property-name:before, .afx-medium-background .sw-property.sw-required .sw-property-name:before, .aw-layout-sublocationContainer .sw-property.sw-required .sw-property-name:before {
    color: #ff0000 !important;
}

/* Change the color of Cell in Edit mode */
/*.aw-jswidgets-editableGridCell,
input.aw-search-searchBox,
aw-splm-table .aw-jswidgets-editableGridCell {
    border-color: #aaaaaac7 !important;
}

.aw-jswidget-tabContainer .aw-jswidget-controlArrow,
.aw-jswidget-tabContainer .aw-jswidget-controlArrowNoFloat {
    font-size: x-large !important;
}*/

/* This change is for Home page Help tile */
.ulhelplink.aw-base-normal a {
    font-size: x-large;
}

/*for changing close in message-box*/
.noty_close>.aw-base-icon {
    width: 15px;
    height: 15px;
    border: 1px solid;
}


/*for changing message-box style for drag*/

#noty_bottom_layout_container {
    cursor: move !important
}

span.noty_text {
    cursor: auto !important
}

/*for displaying extra header properties properly when we click More option link*/

.aw-layout-headerProperties .sw-property.sw-component .sw-property-name ,.aw-layout-headerProperties .sw-property.sw-component .sw-property-val {
 font-size: 10px !important;
 padding-right: 2px !important;
 padding-top: 0.3px !important;
 padding-left: 0.3px !important;
 padding-bottom: 0.3px !important;
}
.aw-layout-flexRow {
    display: flex !important;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: flex-start !important;
}
.aw-layout-headerContribution .aw-layout-headerProperty.sw-row.sw-property {
    margin-bottom: 0.5rem !important;
}

.aw-layout-headerProperties .aw-layout-headerProperty .sw-property-name {
    padding-right: .1666666667rem !important;
    border: 0rem solid transparent !important;
    flex: none !important;
    margin-right: 0 !important;
} 
/* For space beween add and datasettype in popup window*/
.aw-popup-contentContainer.aw-popup-contentContainerBackground.aw-popup-animate.afx-content-background.ui-draggable>div>div.gwt-Label.ng-binding {
    white-space: pre !important
}

.aw-widgets-propertyWarningLabel {
    color: #dc0000 !important
}

.aw-aria-border {
    outline: 0;
    border: .0rem solid transparent !important
}

.awRoot .aw-layout-headerContribution,
aw-page .aw-layout-headerContribution,
aw-showobject-page .aw-layout-headerContribution {
    width: 100%;
    height: 120%;
}

/* This change for PCR Calculator tile */
table.pcrCalculatortable,
.pcrCalculatortable tr,
.pcrCalculatortable th {
    border: 1px solid black;
    border-collapse: collapse;
    margin-left: 2px;
    margin-top: 3px;
}

/* For PCR Prod Update close button in popup window*/
.aw-popup-close {
    color: #ffffff !important;
    margin-right: 30px !important;
    font-size: 15px;
}

.aw-popup-close:after {
    padding-left: 2px;
    content: 'Close';
    font-size: 15px;
    font-family: serif;
}

.aw-splm-tablePropertyValueLinks:hover {
    color: rgb(223, 104, 13) !important;
}

.sw-button {
    width:100% !important
}

.button_width{
    width: 10rem !important;
}

.refresh_all{
    background-color: #f0f0f0 !important;
    color: black !important;
    width: 7rem !important;
    text-align: center !important;
    font-weight: bold !important;
    padding: 1px 6px !important;
}

/* START - FOLLOWING CONTENT IS FROM jquery.alerts.css FILE */
#popup_title {
	font-size: 14px !important;
	font-weight: bold !important;
	text-align: center !important;
	line-height: 1.75em !important;
	color: #FFFFFF !important;
	background: #006699 !important;
	border: solid 1px #006699 !important;
	border-bottom: solid 1px #006699 !important;
	cursor: default !important;
	padding: 0em !important;
	margin: 0em !important;
}

#popup_container {
	font-family: AntipastoRegular, sans-serif !important;
	font-size: 14px !important;
	min-width: 300px !important; /* Dialog will be no smaller than this */
	max-width: 600px !important; /* Dialog will wrap after this width */
	background: #FFFFFF !important;
	border: solid 5px #006699 !important;
	color: #000000 !important;
	-moz-border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	border-radius: 5px !important;
	z-index: 1111111 !important;
}

#popup_content {
	/*background: 16px 16px no-repeat url(images/info.gif) !important;*/
	padding: 1em 1.75em !important;
	margin: 0em !important;
}

/*
#popup_content.alert {
	background-image: url(images/info.gif) !important;
}

#popup_content.confirm {
	background-image: url(images/important.gif) !important;
}

#popup_content.prompt {
	background-image: url(images/help.gif) !important;
}
*/

#popup_message {
	/*padding-left: 10px !important;*/
	margin:0px auto !important;
}

#popup_panel {
	text-align: center !important;
	margin: 1em 0em 0em 1em !important;
}

#popup_prompt {
	margin: .5em 0em !important;
	}


#popup_container INPUT[type='button'] {
    width: 100px !important;
	height: 30px !important;
	border: outset 2px #006699 !important;
	color: #FFFFFF !important;
	background: #006699 !important;
}

#popup_container a{
	display: inline
}
/* END - ABOVE CONTENT IS FROM jquery.alerts.css FILE */


/* START - CSS styles for Target, Min, Max Validation error */

.TMMtooltip {
    position: relative;
    display: inline-block;
}

.TMMtooltip .TMMtooltiptext {
    visibility: hidden;
    color: darkred;
    border-radius: 7px;
    padding: 5px 10px;
    /* To set the bottom position of the tooltip */
    position: absolute;
    z-index: 1;
    top: 0em;
    left: 55%;
    margin-left: -65px;
    font-size: 85%;
    font-style: italic;
    font-weight: 900;
}

.TMMtooltip:hover .TMMtooltiptext {
    visibility: visible !important;
}

/* END - CSS styles for Target, Min, Max Validation error */


/* START - CSS styles for PCR Validation error */
.PCRtooltip {
    position: relative;
    display: inline-block;
}

.PCRtooltip .PCRtooltiptext {
    visibility: hidden;
    background-color: red;
    color: whitesmoke;
    border-radius: 7px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;

    font-style: italic;
}

.PCRtooltip .PCRtooltiptext::after {
    content: "";
    position: absolute;
    /* To position the tooltip */
    top: 100%;
    left: 50%;
    margin-left: -6px;

    /* To add an arrow */
    border: 6px solid;
    border-color: red transparent transparent transparent;
}

.PCRtooltip .PCRtooltiptext {
    visibility: visible;
}

/* END - CSS styles for PCR Validation error */

/* Synch Library button - CSS */
.syn_button {
    width: auto !important;
    padding: 4px 8px !important;
}

/* Wrapping of text in textarea */
.aw-splm-tableEditCellTop .aw-jswidgets-tableEditContainer textarea.sw-property-val {
    height: 100% !important;
 }

 .button_alignright{
    width: 12rem !important;
    float:right !important;
}