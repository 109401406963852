/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-comparesplitviewjs-uniqueInSource.sw-component {
    border-left-color: $Status_Red_6;
}

.aw-comparesplitviewjs-matched.sw-component {
    border-left-color: $Status_Blue_6;
}

.aw-comparesplitviewjs-different.sw-component {
    border-left-color: $Status_Orange_6;
}

.aw-comparesplitviewjs-uniqueInTarget.sw-component {
    border-left-color: $Status_Red_6;
}

.aw-comparesplitviewjs-borderStatusBlue6{
    border-color: $Status_Blue_6;
}

.aw-comparesplitviewjs-borderStatusOrange6{
    border-color: $Status_Orange_6;
}

.aw-comparesplitviewjs-borderStatusRed6{
    border-color: $Status_Red_6;
}

.aw-comparesplitviewjs-backgroundStatusBlue6{
    background-color: $Status_Blue_6;
}

.aw-comparesplitviewjs-backgroundStatusOrange6{
    background-color: $Status_Orange_6;
}

.aw-comparesplitviewjs-backgroundStatusRed6{
    background-color: $Status_Red_6;
}

aw-splm-table .aw-splm-tableCellTop.aw-structurecompare-propertydiff {
    background-color: $Status_Yellow_6;
}
