/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-projmgmt-panelBtnRight {
    margin-right: 0;
}