/* Copyright 2020 Siemens Product Lifecycle Management Software Inc. */

.aw-viewer-viewer3DSubCommandToolbar {
    background-color: $brand_primary_content_background;
}

.aw-threeDViewer-loadbarBackground {
    background-color: $PL_Black_25;
}

.aw-threeDViewer-loadbar {
    background-color: $Siemens_Blue_11;
}