// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* geometric tool */

.aw-geometrictool-sectionCellContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.aw-geometrictool-sectionOffset {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 60%;
}

.aw-geometrictool-sectionOffsetLabel {
    display: inline-block;
    padding-bottom: 6px;
    width: 10%;
}

.aw-geometrictool-volumeCornersText {
    font-size: $fontsize_normal;
    font-weight: 600;
}

.aw-geometrictool-volumePopUpText {
    font-size: $fontsize_normal;
    font-weight: 600;
    padding-bottom: $padding_normal;
}

select.aw-geometrictool-sectionPlane {
    width: auto;
}

.aw-geometrictool-proximityLabel {
    .sw-property-name {
        padding-bottom: $padding_normal;
        font-size: $fontsize_normal;
        font-weight: 600;
    }
}

.aw-geometrictool-sectionSlider {
    float: left;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
}

.aw-geometrictool-sliderButtonPanel {
    display: inline-block;
}

.aw-geometrictool-sliderPanel {
    margin-right: 35px;
}

.aw-geometrictool-volumePanel {
    height: 100%;
    width: 100%;
}

.aw-geometrictool-scrollPanel {
    display: inline-block;

    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.aw-geometrictool-volumePanelFooter {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.aw-geometrictool-volumePanelHeader {
    height: 45px;
}


.aw-geometrictool-volumeCornersRow {
    width: 100%;
}

.aw-geometrictool-volumeCornersColLabel {
    display: inline-block;
    width: 10%;
    padding: 6px;
}

.aw-geometrictool-volumeCornersColValue {
    display: inline-block;
    width: 40%;
}

.aw-geometrictool-volumeCornersLabel {
    display: inline-block;
    margin-left: 55px
}

.aw-geometrictool-sectionPlaneDropDown {
    padding-bottom: 10px;
}

.aw-geometrictool-volumeSearchButton:disabled {
    opacity: 0.3;
    cursor: default;
}

.aw-geometrictool-proximityValue {
    display: inline-block;
    width: 60%;
}

.aw-geometrictool-proximityUnitLabel {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 12px;
}

.aw-geometrictool-drawCapsLinesLabel {
    font-size: $fontsize_x_large;
}

.aw-geometrictool-sectionCellItem {
    height: 35px;
}

.aw-geometrictool-offset {
    float: left;
    width: 70px;
    margin-left: 30px;
    margin-top: -5px;
}

.aw-geometrictool-sliderDiv {
    margin-top: 10px;
    margin-left: 6px;
}

.aw-geometrictool-slider .sw-slider{
    margin: 0;
}

.aw-geometrictool-sectionCellSliderItem {
    @include flexbox();
    @include flex-direction(row);
    @include align-self(flex-start);
    z-index: 99999;
}

.aw-geometrictool-planeDropDownSelected {
    margin-left: 18px;
    padding-top: 10px;
}

.aw-geometrictool-planeDropDownNonSelected {
    margin-left: 60px;
}

.aw-geometrictool-showCapsAndCutLines {
    margin-bottom: 10px;
}

.aw-geometrictool-offsetLabel {
    align-self: center;
}

.aw-geometrictool-measureTree {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.aw-geometrictool-measureTreeNode {
    margin-left: 20px;
}

.aw-geometrictool-offsetLabel .aw-widgets-propertyLabelTop {
    padding: 0 3px 0 0;
}

.aw-geometrictool-sectionsSvg {
    height: 16px;
    width: 16px;
    display: inline-block;
}

.aw-geometrictool-state{
    float: left;
    width: 32px;
    height:32px;
}

.aw-geometrictool-label{
    margin-top: 12px;
    margin-left: 38px;
}

.aw-geometrictool-sliderValue{
    margin-left: 52px;
    margin-top: 17px;
    text-align: end;
}

.aw-geometrictool-cutlines {
    width: 147px;
    padding-top: 10px;
    margin-left: 10px;
    float: left;
}

.aw-section-checkbox {
    margin-bottom: 5px;

    button {
        margin-left: -5px;
    }
}

.aw-section-checkbox-label {
    margin-left: -16px;
}

.aw-geometrictool-on-button-label {
    padding-left: 5px;
}

.aw-geometrictool-cutLineDiv {
    margin-top: 7px;
    display: inline-block;
}

.aw-geometrictool-dropDownList {
    min-width: 80px;
    position: absolute;
    cursor: pointer;
    border-width: 1px;
    border: 1px solid;
}

.aw-geometrictool-dropDownList .aw-geometrictool-selectedItemsBox {
    box-sizing: border-box;
    font-size: .75rem;
    padding: .438rem;
}

.aw-geometrictool-selectedItemsBox .aw-geometrictool-itemsList {
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
        display: inline;
    }
}

.aw-geometrictool-dropDownList .aw-geometrictool-optionsList {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9999;
    position: absolute;
    width: 100%;
    border-width: 1px;
    border-top: 1px inset;

    .aw-geometrictool-optionsItemList {
        list-style-type: none;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            margin: 0;
            width: 100%;
            padding: 0;
            padding: 5px;
            cursor: pointer;
            height: 22px;
        }
    }
}

.aw-geometrictool-dropDownIcon {
    right: 0.4rem;
    width: .75rem;
    height: .75rem;
    position: absolute;
    top: 55%;
    margin-top: -8px;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.aw-geometrictool-dropDownListLabel {
    margin-left: 20px;
    margin-top: -15px;
    min-height: 13px;
}

.aw-geometrictool-editableOffset {
    width: 84px;
    float: left;
    padding-top: 4px;
    margin-left: 20px;
}

.aw-geometrictool-toggleButton {
    margin-right: 4px;
}

.aw-geometrictool-toggleButtonLabels {
    margin-top: -7px;
    margin-left: 13px;
}

.aw-geometrictool-toggleButtonDiv {
    width: 57px;
    float: left;
}

@media screen and (-ms-high-contrast: none) {
    .aw-geometrictool-toggleButtonIe {
        margin-left: 17px;
        margin-top: 5px;
    }
}
