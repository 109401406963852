 .box {
     width: 25%;
 }

 table tr th {
     font: Bold Segoe UI;
     color: black;
 }

 table tr td {
     font: Segoe UI;
 }

 table,
 th,
 td {
     border: 0px solid gray;
     border-collapse: collapse;
     padding: 5px;
 }

 table tr td,
 td,
 th {
     text-align: center;
 }