/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2015.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

/* pmitool */
.aw-pmitool-showSection{
    div.sw-section.sw-section-content.sw-column{
        margin-top:rem-calc(12);
    }
}
.sw-column > .sw-section:not(:last-child).aw-pmitool-showSection {
    margin-bottom: 0;
    padding-bottom: 0;
}

.treePmi {
    li.aw-ui-treeNode .aw-ui-treeNode-valueContainer {
        padding:0;
        display: flex;
        align-items: center;
    }
}

.pmiTool-pmiTree {
    display: inline-flex;
    align-items: center;
    padding: rem-calc(5);

    label.sw-property.sw-row.sw-autoSize,
    label.sw-property.sw-component.sw-nonText {
        width:rem-calc(30);
        margin-bottom: 0;
    }
}
