/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2021.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/


.aw-subset-recipeLogicNot{
    color: $Siemens_Status_Red;
}

.aw-filter-commands{
    box-shadow: $drop_shadow_normal;
    -moz-box-shadow: $drop_shadow_small;
    -webkit-box-shadow: $drop_shadow_small;
}

.aw-filter-italicText {
    font-style: $changed_content_font_style;
}
