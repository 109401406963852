/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2015.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

/* capturetool */

.aw-capturetoolcommandpanels-imagecapture .aw-base-scrollPanel{
    margin-top: rem-calc(12);
}

.aw-capturetoolcommandpanels-imagecapture .aw-layout-collapsiblePanelSectionTitle{
    margin-bottom:0;
}

