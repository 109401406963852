// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* viewertool */

.aw-viewertool-floorPanel {
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.aw-viewertool-planeDropDown {
    height: 28px;
    margin: 10px 0 0 30px;
}

.aw-viewertool-floorSection {
    width: auto;
    margin-top: 5px;
    margin-left: 32px;
}

.aw-viewertool-materialAnchor {
    height: 28px;
    display: block;
}

.aw-viewertool-viewerSettings .aw-layout-panelBody {
    font-size: $fontsize_x_large;
}

.aw-viewertool-slider {
    height: 28px;
    margin: 5px 0 0 30px;
}

.aw-viewertool-sliderLabel {
    margin: 10px 0 0 30px;
}

.aw-viewertool-materialWidget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.aw-viewertool-materialPopup {
    position: fixed;
    overflow: visible;
}

.aw-viewertool-materialGrid {
    width: 130px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;

    z-index: 99999;
}

.aw-viewertool-materialGrid .aw-base-iconButton {
    width: 24px;
    height: 24px;
    padding: 0;
}

.aw-viewertool-materialGrid .aw-base-icon {
    width: 24px;
    height: 24px;
}

.aw-viewertool-materialGrid img {
    float: left;
}

.aw-viewertool-selectedMaterialLabel {
    float: left;
    text-decoration: none;
    height: 28px;
}

.aw-viewertool-selectedMaterialLabel:hover {
    text-decoration: underline;
    cursor: pointer;
}

.sw-section >.aw-viewertool-selectedMaterial:not(:last-child){
    height:rem-calc(32);
    margin-bottom:rem-calc(2); /*Its to avoid sw-row style apply on checkbox within it.*/
    padding-left: rem-calc(1);
}

.aw-viewertool-selectedMaterialIcon {
    width: rem-calc(30);
    margin-bottom: rem-calc(8);
    padding-top: rem-calc(5);
    margin-left: rem-calc(-10);
    margin-right: rem-calc(5);
}

.aw-viewertool-selectedMaterialText {
    margin-bottom: rem-calc(10);
    padding-top: rem-calc(5);

}

.aw-viewertool-selectedMaterialIcon .sw-image-icon {
    width: rem-calc(16);
    height: rem-calc(16);
    cursor: pointer;
    margin-left: rem-calc(6);
}

.aw-viewertool-popupMaterialIcon {
    display: flex;
    flex-wrap: wrap;
    padding-left: rem-calc(7);
    padding-top:rem-calc(7);
    padding-bottom: rem-calc(7);
}

.sw-section .aw-viewertool-selectedMaterial > .aw-viewertool-selectedMaterialCheckbox:not(:last-child){
    margin-right: 0 ; /*Its to avoid sw-row style apply on checkbox within it.*/
    width: rem-calc(42) ;
}

.aw-viewertool-displayUnits{
    padding-left: $padding_small;
}
