// Copyright 2019 Siemens Product Lifecycle Management Software Inc.

.aw-change-changeSummaryTable .aw-splm-table .ui-grid-row.aw-change-changeSummaryTableOddRow {
    background-color: $aw_gray_25;
}

.aw-change-changeSummaryTable .aw-splm-table .aw-splm-tableViewport .ui-grid-row-selected.aw-change-changeSummaryTableOddRow {
    background: $cell_list_item_selected;
    color: $brand_selection_text_selected;
}

.aw-change-changeSummaryTable .aw-splm-table .aw-splm-tableViewport .ui-grid-row:hover.aw-change-changeSummaryTableOddRow {
    background: $cell_list_item_hover;
    color: $brand_selection_text_hover;
}

.aw-change-changeSummaryTable .aw-splm-table .aw-splm-tableViewport .ui-grid-row:hover.ui-grid-row-selected.aw-change-changeSummaryTableOddRow {
    background: $cell_list_item_selected_hover;
    color: $brand_selection_text_selected_hover;
}

.aw-change-addedEntry {
    color: $AW_Green;
}

.aw-change-changeSummaryTable .aw-splm-table .ui-grid-row-selected .ui-grid-cell,
.aw-change-changeSummaryTable .aw-splm-table .ui-grid-row:hover .ui-grid-cell,
.aw-change-changeSummaryTable .aw-splm-table .ui-grid-row .ui-grid-cell {
    background-color: transparent;
}

/* Change summary styling */
.aw-change-changeSummaryTable .aw-change-addedEntry {
    color: $AW_Green;
}

.aw-change-changeSummaryTable .aw-change-removedEntry {
    color: $aw_ns_red;
}

.aw-commands-commandIconButtonForChangeSummary:hover {
    background-color:  $Natural_Blue_8;

}

.aw-change-changeSummaryTable .aw-splm-table .ui-grid-row {
    &.aw-change-changeSummaryTableTopBorder {
        border-top: 2px solid;
    }

    &.aw-change-changeSummaryTableBottomBorder {
        border-bottom: 3px solid;
    }
}

.aw-change-mergeCandidatesTile {
    .add {
        color: $PL_Green_25;
        font-weight: bold;
    }
}

.aw-change-mergeCandidatesTile {
    .remove {
        color: $Siemens_Status_Red;
        font-weight: bold;
    }
}

.aw-change-mergeCandidatesTile {
    .replace {
        color: $PL_Green_25;
        font-weight: bold;
    }
}

.aw-change-mergeCandidatesTile {
    .propertychange {
        color: $PL_Green_25;
        font-weight: bold;
    }
}

.aw-change-commandBarBackground{
    background-color: $panel_section_title_background;
}

.aw-change-mergeCandidateConflictTileBorder{
    border-color: #DC0000;
}
