// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* inbox */

.aw-inbox-searchBox {
    height: 29.000px;
    padding: 0;
    width: 100%;
    outline: none;
    margin-bottom: 0;
    margin-top: 12px;
    float: left;
}

.aw-inbox-addButton,
.aw-inbox-removeButton {
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 6.667px;
    margin-top: 1.333px;
    padding: 0;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -ms-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.aw-inbox-performTaskToolAndInfoArea input[type="radio"],
.aw-inbox-performTaskToolAndInfoArea input[type="checkbox"] {
    width: auto;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-radioButtonText,
.aw-inbox-performTaskToolAndInfoArea .aw-inbox-checkBoxText {
    font-size: $fontsize_small;
    font-weight: 600;
    float: left;
    width: 100%;
}

.aw-inbox-radioButtonText label,
.aw-inbox-checkBoxText label {
    font-size: $fontsize_large;
    margin-left: 5px;
}

.aw-inbox-quorumContentDispaly {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -ms-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-checkBoxText {
    display: block;
}

.aw-inbox-performTaskToolAndInfoArea .aw-layout-left,
.aw-inbox-performTaskToolAndInfoArea .aw-layout-right {
    white-space: nowrap;
}

.aw-inbox-performTaskToolAndInfoArea .aw-widgets-requiredIndicator,
.aw-inbox-performTaskToolAndInfoArea .aw-widgets-patternListBox {
    float: left;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-requiredCountLabel {
    font-size: $fontsize_small;
    padding-right: 10px;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumTxtB {
    width: 50px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -ms-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumTxtB .propertyLabelTopContainer {
    display: none;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumTxtB .aw-widgets-propertyErrorLabel {
    width: 100%;
    padding-bottom: 2px;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumPercentTxtB {
    width: 50px;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumPercentTxtB .propertyLabelTopContainer {
    display: none;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-quorumPercentTxtB .aw-widgets-propertyErrorLabel {
    width: 100%;
    padding-bottom: 2px;
}

.aw-inbox-performTaskToolAndInfoArea .aw-inbox-performTask .aw-inbox-myDecisionPerformTask .aw-layout-panelHeader,
.aw-inbox-performTaskToolAndInfoArea .aw-inbox-performTask .aw-inbox-myDecisionPerformTask .aw-layout-panelBody,
.aw-inbox-performTaskToolAndInfoArea .aw-inbox-performTask .aw-inbox-myDecisionPerformTask .aw-layout-panelFooter {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.aw-inbox-myDecisionPerformTask .aw-layout-panelSection:last-child {
    margin-bottom: 0;
}

.aw-inbox-userInstructions {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    float: left;
}

.aw-inbox-userSelection,
.aw-inbox-userListPanel {
    height: inherit;
}

.aw-inbox-userSelection>.aw-inbox-userListPanel {
    float: left;
}

.aw-inbox-userSelection>h3.aw-widgets-cellListCellTitle {
    cursor: default;
}

.aw-inbox-outOfOfficeToolAndInfoArea .aw-layout-panelHeader .aw-layout-panelSection {
    margin-bottom: 5px;
}

.aw-inbox-outOfOfficeToolAndInfoArea .aw-layout-panelHeader .aw-inbox-newRecipient {
    margin-bottom: -15px;
}

.aw-inbox-taskRecipient .gwt-Label {
    margin-bottom: 10px;
    /* this is to add a bottom margin to the no-recipients label */
}

.aw-widgets-cellListCellTitleBlock.aw-inbox-unReadCellTitle .aw-widgets-cellListCellItemType {
    font-weight: bold;
}

.aw-inbox-quorumLabel {
    font-size: $fontsize_small;
    padding: 1px 1px 1px 5px;
    text-align: left;
    vertical-align: bottom;
}

.aw-inbox-quorumPercentContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -ms-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -ms-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.aw-inbox-decisionTask .aw-layout-panelSection {
    width: auto;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -ms-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.aw-inbox-decisionTask .aw-inbox-commentPanelSection {
    margin-bottom: 0;

    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.aw-inbox-commentPanelSection {
    margin-bottom: 0;
}

.aw-inbox-commentPanelSection textarea {
    margin-bottom: 0;
}

.aw-inbox-decisionLabelButtons {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.aw-inbox-decisionLabelButtons button:not(:last-child) {
    margin-bottom: 10px;
}

.aw-inbox-performTaskSecondaryArea .aw-layout-panelHeader,
.aw-inbox-performTaskSecondaryArea .aw-layout-panelBody,
.aw-inbox-performTaskSecondaryArea .aw-layout-panelFooter {
    padding: 0;
}
