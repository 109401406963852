/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2018.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-requirement-hint {
    font-size: $fontsize_large;
    font-weight: normal;
    font-style: italic;
    clear: both;
    float: left;
    width: 100%;
    opacity: 0.5;

    button.sw-button-iconContainer {
        display: contents;
    }
}

.aw-requirements-cellListCellContent {
    float: left;
    width: 100%;
}

.aw-requirements-cellListCellContent .aw-widgets-cellListCellProperties .aw-widgets-propertyLabel{
    overflow: unset;
    max-width: 100%;
    white-space: unset;
    font-weight: bold;
}

.aw-requirements-propertyLabel{
    overflow: hidden;
    display: inline;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: $padding_small;
}

.aw-requirements-cellTitle {
    display: inline-block;
    padding-right: $padding_small;

}

.aw-requirements-propertyValue{
    display: block;
}
.aw-requirements-excelImportHeader {
    align-items: center;
    display: flex;
}

.aw-requirements-emptyCell{
    width: 100%;
    height: rem-calc(32);
}

.aw-requirements-FolderImg{
    max-width: rem-calc(24);
}

.aw-requirements-FolderText{
    margin-bottom: rem-calc(8);
    .aw-splm-tableCellText{
        padding-left: 0;
        padding-top: rem-calc(12);
    }
}
.aw-requirements-removeButton{
    width: 100%;
}

.aw-requirements-addRuleLabels{
    margin-top: rem-calc(10);
}
.aw-requirements-excelImportHeader .propertyLabelTopContainer {
    width: 100%;
    white-space: nowrap;
}

.aw-requirements-excelImportMappingSection .aw-widgets-propertyLabelTopValueContainer ul> :nth-last-child(3),
.aw-requirements-excelImportHeader .aw-widgets-propertyLabelTopValueContainer ul> :nth-last-child(3) {
    font-style: italic;
    opacity: 0.6;
}

.aw-requirements-tracelinkCellType {
    float: left;
    margin-top: 25px;
}

.aw-requirement-traceLinkIconSpace {
    margin-left: 5px;
}

.aw-requirement-createTracelink .aw-layout-panelSection {
    margin-bottom: 0;
}

.aw-requirements-compareHistory {
    overflow: hidden;
    width: 100%;
    .aw-widget-pic.aw-widget-thumbnail .aw-base-icon {
        height: 22px;
        width: 22px;
    }
}

.aw-requirements-compareHistory diffreport h3{
    justify-content: space-between;
    width: 100%;
    contain: content
}

.aw-requirements-compareHistory diffreport h3 .aw-requirements-compareSpecVersionHistory,
.aw-requirements-compareReportLabel {
    font-size:  $fontsize_normal;
    font-weight: 400;
    float: right
}

.aw-requirements-conflictCompareReport .aw-requirements-conflictCompareReportContent {
    margin-top: 0;
    width: auto;
}

.aw-requirement-legendItem .aw-requirement-legendItemLabel,
.aw-requirements-compareReqHistory  h5{
    font-size:  $fontsize_normal;
}


.aw-requirements-compareContent {
    margin: 5px 24px 5px 24px;
}

.aw-requirements-historyHeaderCell {
    display: inline-block;
    width: 20%;
}

.aw-requirements-compareHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

/*
* Styles for the HTML Diff
*/

.aw-requirements-compareHistory span.diff-html-added {
    font-size: 100%;
}

.aw-requirements-compareHistory span.diff-html-removed {
    font-size: 100%;
}

.aw-requirements-compareHistory span.diff-html-changed {
    font-size: 100%;
}

.aw-requirements-compareHistory span.diff-html-added img {
    border-width: 2px;
    border-style: solid;
}

.aw-requirements-compareHistory span.diff-html-removed img {
    border-width: 2px;
    border-style: solid;
}

.aw-requirements-compareHistory span.diff-html-changed img {
    border-width: 2px;
    border-style: solid;
}

.aw-richtexteditor-importPreviewPanel {
    width: 100%;
    .aw-richtexteditor-documentPaper {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.aw-richtexteditor-importPreviewPanel>.aw-base-scrollPanel {
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;
    overflow-x: auto;
    height: 100%;
}

.aw-requirement-hint svg {
    width: 20px;
    height: 20px;
}

.aw-requirements-typePropertiesCellContent .aw-widgets-cellListCellItemType {
    width: auto;
}

.aw-requirements-typePropertiesCellContent .aw-widgets-cellListCellContent {
    width: 100%;
}

.aw-requirement-mappingRuleButton {
    margin: 0;
}

.aw-requirement-reqIFPropertyContainer {
    margin-left: 8px;
}

.aw-requirement-reqIFLovContainer {
    margin-left: 8px;
}

.aw-requirements-excelImportHeader .aw-widgets-propertyLabelTop {
    overflow: hidden;
    text-overflow: ellipsis;
}

.aw-requirement-importPanel .aw-layout-panelSection .aw-layout-panelSectionContent {
    width: 100%;
    padding: 8px 0 0;
}

.previewElement {
    .diff-html-removed span,
    .diff-html-removed h1,
    .diff-html-removed h2,
    .diff-html-removed h3 {
        text-decoration: line-through;
    }

    .aw-requirements-discardUpdate.diff-html-added,
    diffreport span.diff-html-removed {
        border-width: thin;
        border-style: dashed;
    }

    .diff-html-changed,
    diffreport span.diff-html-added {
        border-width: thin;
        border-style: solid;
    }

    .aw-requirements-discardUpdate.diff-html-removed {
        text-decoration: unset;
    }

    .aw-requirements-unfreezedReqIndicator,
    .aw-requirements-freezedChangedReqIndicator,
    .aw-requirements-freezedReqIndicator,
    .aw-requirements-frozenToLatestReqIndicator,
    .aw-requirements-rejectedOverwrittenIndicator {
        border-left-width: 5px;
        margin-left: -15px;
        height: calc(100% - 30px);
        position: absolute;
        margin-top: 10px;
    }

    .aw-requirements-freezedChangedReqIndicator,
    .aw-requirements-frozenToLatestReqIndicator{
        border-style: dashed;
        border-width: initial;
    }

    .aw-requirements-unfreezedReqIndicator {
        border-style: dotted;
    }

    .aw-requirements-freezedReqIndicator,
    .aw-requirements-rejectedOverwrittenIndicator {
        border-style: outset;
    }

    .requirement {
        position: relative;
    }
}

.aw-requirements-exportReqIFPanel .aw-requiremnts-reqIFfileNameRow {
    align-items: flex-end;
}

.aw-requirements-exportReqIFPanel .aw-requiremnts-reqIFfileNameColumn {
    width: 90%;
}

.aw-requirements-exportReqIFPanel .aw-requiremnts-reqIFfileExtensionColumn {
    margin-bottom: 8px;
}

.aw-requirements-exportReqIFPanel .aw-requiremnts-reqIFfileNameWidget {
    width: 100%;
}

.aw-requirements-tracelinkCellIcon {
    width: rem-calc(32);
    height: rem-calc(32);
}

.aw-requirement-createTracelink .aw-layout-panelSectionContent {
    margin-bottom: 0;
}

.aw-panel-footer.aw-requirement-tracelinkPopupButtonFooter {
    padding: $padding_normal $padding_large;
    margin-top: $margin_normal;
    margin-bottom : $margin_small;
}
.aw-tracelink-cell .aw-widgets-cellListCellContent{
    overflow: hidden;
    padding-bottom : $padding_xsmall;
    width: rem-calc(250);
}
.aw-panel-footer.aw-requirement-tracelinkPopupTypeFooter {
    display: block;
    padding: 0 $padding_normal;
    max-height: 45%;
    overflow-y: auto;
}

.aw-requirement-tracelinkPopupTypeFooter .aw-layout-panelBody {
    padding: 0;
}

.aw-requirement-tracelinkPopupTypeFooter .aw-layout-panelSectionContent {
    margin-bottom: 0;
}

.aw-requirement-createTracelink .aw-requirement-swapTracelinkButton .aw-commands-commandIconButton {
    height: 32px;
    width: 32px;
    padding: 5px;
}

.sw-popup-layout .sw-popup-contentContainer .sw-column .aw-requirement-createTracelinkPanelBody{
    padding: 0 $padding_large;
}

.aw-requirement-createTracelink .sw-section .sw-section-content {
    margin:auto;
}

.aw-requirement-createTracelinkPanelBody .aw-requirement-createTracelink li.aw-widgets-cellListItem {
    padding: 4px;
}

.aw-requirement-createTracelink .aw-widgets-modelObjectList {
    margin: 0;
}

.aw-requirements-tracelinkCellIcon aw-model-icon .aw-base-icon {
    padding: 4px;
}

.aw-tracelink-cell {
    align-items: center;
}

.aw-requirement-createTracelink .aw-layout-panelSectionTitle {
    height: 32px;
    padding-left: 8px;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.aw-requirement-chip {
    margin: 10px 0 10px 40px;
}
.aw-requirement-chip .aw-widgets-chipLabel{
    padding-left: 35px;
    padding-right: 35px;
}

.aw-requirements-importOfficePanel .aw-xrt-thumbnailImagePanel .aw-base-icon{
    height: 24px;
    width: 24px;
}

.aw-requirement-ruleProcessor{
    width: 100%;
}

.aw-requirement-chipList{
    min-height: 40px;
    height:auto;
    flex: none
}
.aw-requirement-chipList .aw-widgets-chipList{
    @include flex-wrap(wrap);
    max-width: 100%;
}

.aw-requirement-createTracelink  {
    .requirements-Droppable-Lists {
        width: 100%;
    }

    button[button-id='Arm0TracelinkStartSectionDelete'], button[button-id='Arm0TracelinkEndSectionDelete'] {
        float: right
    }
}

.aw-requirements-compareTextComparisonPreviewText {
    padding: 7px 0 0 10px;
}

.aw-requirement-importPreviewSWA{

    .aw-layout-panelFooter {
        padding-top: 4px;
        padding-bottom: 4px;

        button.aw-base-blk-button.aw-base-size-auto {
            margin-right: 1em;
            min-width: 200px;
        }

        button.aw-base-blk-button.aw-base-size-auto:last-child {
            margin-right: 0;
        }
    }
    .aw-requirement-previewPropertyTable {
        .aw-requirement-previewPropertyTableHeader {
            font-weight: $bold_font_weight;
            text-transform: uppercase;
            font-size: $fontsize_x_large;
        }
        table {
            border-collapse: collapse;
            margin: 7px;
            td {
                padding: 4px;
                p {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            tr td:first-child {
                width: 30%;
            }
            .aw-splm-tableHeaderCellLabel span {
                text-decoration: none;
            }
        }
    }
    .aw-requirement-previewMarginRight {
        margin-right: rem-calc(40);
    }
}

.aw-requirements-compareHistory.aw-richtexteditor-documentPaper.aw-richtexteditor-document.aw-richtexteditor-documentPanel {
    margin: 0;
    overflow: auto;
}

.aw-requirements-tracelinkCellIcon .aw-xrt-thumbnailImagePanel .sw-pic{
    height: 28px;
    width: 28px;
}

.aw-requirement-legendItem {
    .aw-requirement-legendItemMarker {
        display: inline-block;
        width: 18px;
        height: 18px;
    }
    .aw-requirement-legendItemLabel {
        display: inline-block;
        font-size: $fontsize_large;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 5px;
        padding-right: 32px;
    }
}

.aw-requirement-tooltipCell .aw-xrt-thumbnailImagePanel img.aw-base-icon{
    height: rem-calc(16);
    width: rem-calc(16);
}


.aw-requirement-tooltipCell span.sw-image-icon{
    height: rem-calc(18);
    width: rem-calc(18);
}

.aw-requirement-tooltipCell .aw-base-icon {
    margin: 0px;
}


.aw-requirements-changeTypePopup{
    width: auto;

    .aw-widgets-cellListWidget{
        width: auto;
    }

    .aw-widgets-emptyCell{
        height: 0;
    }

    .aw-tcWidgets-modelTypeCell img.aw-base-icon{
        height: rem-calc(16);
        width: rem-calc(16);
        margin: 0;
    }

    .aw-tcWidgets-modelTypeCellTitle{
        height: rem-calc(18);
        width: auto;
    }

    .aw-tcWidgets-modelTypeCell {
        height: rem-calc(18);
        width: auto;
        display: inline-block;
    }
}

.aw-requirements-changeSettingsPopup{

    .aw-widgets-emptyCell{
        height: 0;
    }
}

.aw-requirements-actionsPopup{

    .aw-tcWidgets-modelTypeCellTitle{
        height: rem-calc(18);
        width: auto;
    }
}


.aw-requirements-excelImportMappingSection .aw-requirements-mapping-chevron-icon .aw-jswidgets-choice.gwt-ListBox{
    border-style: unset;
    padding: inherit;
}

.aw-requirements-polarionLink{
    .sw-aria-border {
        border: 0;
    }
}

.aw-requirements-tracelinkAddPanelBodyContainer .aw-layout-panelBody, .aw-requirements-traceabilityMatrixPanel .aw-layout-panelBody{
    padding: unset;
}

.aw-requirement-importSubViewContainer {
    margin-left: $margin_normal;
}

.aw-requirement-importSubViewContainer .aw-requirement-importSubViewCheckbox{
    margin-left: rem-calc(-8);
}

.aw-requirements-htmlSpecTmplLocn .sw-primary-workarea {
    height: 100%;
}

.aw-requirement-importSubViewCheckbox div.sw-row{
    display: block;
}

.aw-requirements-createReqSpecTitle  .aw-title-link {
   padding-left: $padding_xsmall;
    padding-right: $padding_xsmall;
}

.aw-requirements-runInBackgroundChechbox {
    flex:auto;
    width: 100%;
}

.aw-requirements-watermarkEditor {
    border: 1px solid;
    margin-top: $padding_xsmall;
    margin-left: $padding_xsmall;
}
.aw-requirements-requiementHistoryContent{
    width: 95% ;
}
.aw-requirement-reqIFfileExtensionLabel{
    margin-bottom:rem-calc(18) ;
}


