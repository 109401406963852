// Copyright 2022 Siemens Product Lifecycle Management Software Inc.

.aw-occmgmtjs-inlineRowRequiredCell::before {
    content:"*";
    font-size: 20px;
    line-height:16px;
    font-weight:700;
    padding:2px;
}

.aw-occmgmtjs-inlineRowRequiredCell::after {
    font-style: italic;
}
