/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-viewer-viewer,
.aw-viewer-viewerPanel {
    width: 100%;
    height: 100%;
    position: relative;
}

.aw-viewer-image {
    display: block;
    margin: auto;
    z-index: 1;
    -ms-background-position-y: 2px;
}

.aw-viewer-thumbnailPanel {
    height: inherit;
    width: inherit;
    position: relative;
    margin: auto;
    object-fit: contain;
}

.aw-viewer-frame {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border: 0;
}

.aw-viewer-effectivityLabel {
    font-size: $fontsize_3x_large;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    z-index: 99;
}

.aw-viewer-emmLoadProgress {
    overflow: hidden;
    background: url(image/miscSessionProgressNeutral32.svg);
    animation: spin 1s infinite linear;
}

.aw-viewer-loadProgress {
    overflow: hidden;
    background: url(image/miscSessionProgressNeutral32.svg);
    animation: spin 0.35s infinite linear;
}

.aw-search-viewer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.aw-viewer-viewThumbnailContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
}

.aw-viewer-viewEmmLoadProgressContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
    pointer-events: none;
    position: absolute;
}

.aw-viewer-viewLoadProgressContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    position: absolute;
    pointer-events: none;
}
