// Copyright 2019 Siemens Product Lifecycle Management Software Inc.

.aw-jswidgets-grid .ui-grid-row.aw-occmgmtjs-inlineRow {
    background-color: $Siemens_Blue_24;
}

.aw-splm-table .ui-grid-row.aw-splm-table-hoverRow.aw-occmgmtjs-inlineRow {
    background: $cell_list_item_hover;
    color: $brand_selection_text_hover;
}

.aw-occmgmtjs-removeInlineRowCommand {
    align-items: center;
}


.aw-occmgmtjs-inlineRowRequiredCell::before {
    color: $Siemens_Blue_7;
}

.aw-occmgmtjs-inlineRowRequiredCell::after {
    color: $Charting_Grey_5;
}


