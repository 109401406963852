/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2018.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-consent-statement {
    padding:10px;
    width:99%;
    font-size:0.75rem;
    max-height:200px;
}

.aw-consent-section {
    padding:30px;
    text-align:justify;
    text-justify:inter-word;
    a {
        display: inline-block;
    }
}
