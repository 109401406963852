// Copyright 2019 Siemens Product Lifecycle Management Software Inc.
.aw-layout-panelMain .aw-layout-panelBody .aw-clauseName-background .aw-layout-panelSectionTitle{
    background-color: $brand_selection_background_selected;
}
.aw-layout-panelMain .aw-layout-panelBody .aw-revRule-clauseModify-indicator .aw-layout-panelSectionTitle {
    border-left: solid;
    border-left-color: $aw_notification_cell_med_color;
}
.aw-revRule-similarClauseErrorText {
    color : $guidance_error_color;
}

.aw-clauseName-background .sw-panel-summary .sw-sectionTitleContainer{
    background-color: $brand_selection_background_selected;
}

.aw-revRule-clauseModify-indicator .sw-panel-summary {
    border-left: solid;
    border-left-color: $aw_notification_cell_med_color;
}

