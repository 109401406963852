/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
@import 'mixins/mixins';

.aw-importexport-header .aw-widgets-propertyLabelTopValueContainer .aw-widgets-propertyNonEditValue {
    font-weight: 600;
}

.aw-importexport-headerLabel .propertyLabelTopContainer .aw-widgets-propertyLabelTop {
    font-weight: 450;
    padding-top: 9px;
}
.aw-splm-table .aw-splm-tableCellTop.aw-importexport-cellTop {
    width: 100%;
    height: 100%;
    margin: 0;
}

.aw-splm-table .aw-splm-tableCellText.aw-importexport-tableCellText {
    margin-left: $margin_small;
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-releaseStatusIcon {
    background: url(image/indicatorReleasedTCMReleased16.svg) left no-repeat;
    background-size: 16px 16px;
    margin-left: 10px;
    .aw-visual-indicator {
        vertical-align: middle;
    }
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-warningIcon {
    background: url(image/indicatorWarning16.svg) left no-repeat;
    background-size: 16px 16px;
    margin-left: 5px;
    .aw-visual-indicator {
        vertical-align: middle;
    }
    text-indent: 16px;
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-useExistingValue {
    font-style: italic;
}
