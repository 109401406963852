// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

.aw-occmgmt-labelAlignmentInTooltip aw-property-label,
.aw-occmgmt-labelAlignmentInTooltip .aw-widgets-propertyLabelTopValueContainer {
    flex: none;
    -webkit-flex: none;
    -ms-flex: none;
}

.aw-occmgmt-labelAlignmentInTooltip .aw-widgets-propertyContainer {
    display: -webkit-inline-box;
    display: -ms-inline-box;
}

.aw-layout-indexUpdateDateSectionContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}


.aw-popup-overlay {
    position: absolute;
}


.aw-occmgmt-effectivityUnitLabel {
    float: left;
    line-height: normal;
    padding: 2px;
}

.aw-occmgmt-unitLabel .sw-property-val{
    width: auto;
    min-width: rem-calc(36);
    padding: 0;
}


.aw-occmgmt-inContextSearch .aw-search-searchBox,
.aw-occmgmt-configurationPanel .aw-search-searchBox {
    width: calc(100% - 24px);
    height: 24px;
    float: left;
    margin: 0;
    padding: 0 8px 2px;
}

.aw-occmgmt-resultsAndFiltersPanel {
    margin-top: 10px;
}

.aw-occmgmt-inContextSearchUpButton {
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
}



.aw-occmgmt-inContextSearch .aw-layout-panelBody {
    padding-top: 10px;
}

.aw-occmgmt-inContextSearchDownButton {
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
}

.aw-occmgmt-inContextSearchSelectAllButton {
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
}

.aw-occmgmt-inContextSearchUpButton .aw-base-icon,
.aw-occmgmt-inContextSearchDownButton .aw-base-icon,
.aw-occmgmt-inContextSearchSelectAllButton .aw-base-icon {
    height: 22px;
    width: 22px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}


.aw-occmgmt-backButton .aw-base-icon {
    height: 24px;
    width: 24px;
}

.aw-occmgmtjs-savedVariantRuleLink {
    word-break: break-all;
}

.aw-occmgmtjs-separator {
    border-top: solid 1px;
    pointer-events: none;
    text-decoration: none;
    cursor: default;
    width: 100%;
}


.aw-occmgmt-innerFlowPanel {
    z-index: 1001;
}


.aw-occmgmt-globalRevRuleLabel {
    padding: 8px;
}

.aw-occmgmt-globalRevRuleLabel:HOVER {
    cursor: pointer;
}

.aw-occmgmt-mainScrollPanel {
    max-height: 400px;
    max-width: 300px;
    min-width: 200px;
    word-wrap: break-word;
}

.aw-occmgmt-unitEffectivity{
    .aw-occmgmt-mainScrollPanel {
        min-width: auto;
        max-width: 100px;
    }

}

aw-ace-date-effectivity,
.aw-occmgmt-dateEffectivity {
    .aw-occmgmt-mainScrollPanel {
        max-height: 400px;
        max-width: 304px;
        word-wrap: break-word;
    }

    .aw-layout-popup {
        z-index: 1001
    }

    aw-date .aw-jswidgets-dateTimeInputbox>div {
        width: 100%
    }
}

.aw-occmgmt-popupScrollPanel {
    max-height: 300px;
}

.aw-occmgmt-contextPopupScrollPanel {
    max-height: 150px;
    cursor: default;
}

.aw-occmgmt-dateEfftyPopupOnCfgPanel {
    max-height: 247px;
}



.aw-occmgmt-cellImageTooltip {
    cursor: pointer;
    overflow: hidden;
    border: 1px solid;
}

.aw-occmgmt-cellImageTooltip:hover,
.aw-occmgmt-cellImageTooltip:active {
    border: 2px solid;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-cellListCellImage {
    overflow: visible;
    margin: 0 8px 0 0;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-cellListCellImage:hover {
    margin: 0 6px -2px 0;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-dataGridCellImage:hover {
    margin-right: 6px;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-gridCellColorContainer,
.aw-occmgmt-cellImageTooltip.aw-widgets-gridCellColorContainer .aw-widgets-modelObjectThumbnail {
    height: 24px;
    width: 24px;
    margin: 0 auto;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-gridCellColorContainer .aw-widgets-modelObjectThumbnail .aw-base-icon {
    height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-cellListCellImage .aw-widgets-modelObjectThumbnail .aw-base-icon,
.aw-occmgmt-cellImageTooltip.aw-widgets-gridCellColorContainer .aw-widgets-modelObjectThumbnail .aw-base-icon {
    object-fit: cover;
}

aw-table-icon-cell .aw-occmgmt-cellImageTooltip .aw-widgets-gridCellColorIndicator {
    width: 0;
}

.aw-occmgmt-cellImageTooltip.aw-tcWidgets-partialOpacity,
.aw-occmgmt-cellImageTooltip.aw-widgets-partialVisibility {
    opacity: 1;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-partialVisibility .aw-base-icon,
.aw-occmgmt-cellImageTooltip.aw-tcWidgets-partialOpacity .aw-base-icon {
    opacity: .2;
}

.aw-occmgmt-cellImageTooltip.aw-widgets-cellListCellImage .aw-base-icon,
.aw-occmgmt-cellImageTooltip.aw-widgets-gridCellColorContainer .aw-widgets-modelObjectThumbnail .aw-base-icon {
    padding: 0;
}

.aw-occmgmt-cellImageTooltip:hover .aw-splm-tableIcon {
    margin-left: -1px;
}

.aw-occmgmt-cellImageTooltip .aw-widgets-gridCellColorIndicator.aw-splm-tableCellColorIndicator {
    width: 0;
}

.aw-occmgmt-summaryRendering {
    float: left;
    font-size: $fontsize_normal;
    margin: 0;

    height: -webkit-calc(100% - 15px);
    height: -moz-calc(100% - 15px);
    height: calc(100% - 15px);
    overflow-y: auto;
}


/*
   Styling definitions for the XRT Pages and Grouped Sections
*/

.aw-occmgmt-pageContainer {
    float: left;
    display: inline;
    width: 100%;
    height: -webkit-calc(100% - 5px);
    height: -moz-calc(100% - 5px);
    height: calc(100% - 5px);
}


/*
   Styling definitions for the XRT Section Widgets within Columns
 */

.aw-occmgmt-inContextSearch .aw-search-searchContainer,
.aw-occmgmt-configurationPanel .aw-search-searchContainer {
    width: 100%;
}

.aw-occmgmt-inContextSearch .aw-search-searchContainer {
    margin: 10px 0;
}

@media (max-width: 64.25em) {

    .aw-occmgmt-inContextSearch .aw-search-searchContainer,
    .aw-occmgmt-configurationPanel .aw-search-searchContainer {
        display: inline-block;
    }
}

.aw-occmgmt-inContextSearchPanelHeader {
    width: 100%;
    padding-top: 10px;
}


.aw-occmgmt-recipeOperatorPopupPanel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.aw-occmgmt-recipeOperatorPopupPanel .aw-widgets-cellListItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    align-items: flex-end;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: justify;
    justify-content: flex-start;
}


aw-occmgmt-sublocation {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    overflow: hidden;
}

aw-occmgmt-secondary-workarea,
aw-occmgmt-secondary-workarea .aw-layout-sublocationContent {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    overflow: hidden;
}

aw-occmgmt-secondary-workarea aw-gwt-presenter {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

aw-occmgmt-secondary-workarea .aw-layout-sublocationContent {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    height: auto;
    width: auto;
}

.aw-layout-panelBody[panel-id="InContextSearchResultsTab"] .aw-commands-wrapper {
    display: inline;
    float: right;
}

.aw-layout-panelBody[panel-id="InContextSearchResultsTab"] .aw-commands-wrapper .aw-commands-svg {
    width: 22px;
    height: 22px;
    padding: 1px;
}

aw-occmgmt-sublocation .aw-layout-summaryList {
    min-width: 0;
}

aw-list aw-occmgmt-cell .aw-widgets-cellListCellContent {
    float: unset;
    width: 100%;
    overflow: hidden;
}

aw-data-navigator aw-navigate-breadcrumb .aw-layout-popup {
    min-width: 302px;
}

.aw-widgets-cellListItem .aw-markup-added *,
.aw-widgets-cellListItem:hover .aw-markup-added * {
    font-style: italic;
}

.aw-widgets-cellListItem .aw-markup-deleted * {
    text-decoration: line-through;
}

.aw-grid-markup-added *,
.aw-occmgmt-multiSelectionEffMessage {
    font-style: italic;
}

.aw-grid-markup-deleted * {
    text-decoration: line-through;
}

.aw-occmgmt-ruleDateDropDown {
    line-height: 14px;
}

.aw-occmgmt-editButtons {
    max-width: 200px;
    float: right;
    margin: 5px 20px;
}

.aw-occmgmt-tooltip-list .aw-widgets-cellListWidget .aw-widgets-cellListItem:hover,
.aw-occmgmt-tooltip-list .aw-widgets-cellListWidget .aw-widgets-cellListItem.aw-widgets-cellListItemSelected,
.aw-occmgmt-tooltip-list .aw-widgets-cellListWidget .aw-widgets-cellListItem.aw-widgets-cellListItemSelected:hover {
    background: unset;
}

.aw-occmgmt-tooltip-list .aw-widgets-cellListItem {
    cursor: unset;
}

.aw-occmgmt-tooltipTextEllipsis {
    margin-bottom: 0;
    .sw-property-val {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        cursor: default;
        max-width: rem-calc(200);
    }
}

.aw-splm-tableCellTop.aw-occmgmt-duplicateEditableIndicator .aw-splm-tableCellText {
    align-self: center;
}


.aw-occmgmtjs-insertLevelPanel .aw-splm-tableHeaderRow>div:nth-child(2)>div {
    position: relative;
    right: 40px;
}

.aw-occmgmtjs-insertElement {
    margin-right: 16px;
    position: relative;
    left: 8px;
}

.aw-occmgmtjs-insertLevelPanel .aw-splm-tablePinnedContainerLeft .aw-splm-tableScrollContents {
    pointer-events: none;
}

.aw-occmgmt-elemEffEndItem span.sw-property-name {
    width: auto;
    min-width: 5rem;
}

.aw-occmgmt-elemEffEndItem .aw-commands-commandIconButton {
    padding: 0;
    margin: 0 10px;
}

.aw-occmgmtRevRule-dropdown {
    width: 300px;
}

@media all and (max-width: $narrowMode_max_width) {
    .aw-occmgmtRevRule-dropdown {
        width: 200px;
    }
}

.aw-occmgmt-split-left {
    padding-left: $padding_normal;
    flex: 1 1 auto;
}

.aw-occmgmt-split-right {
    padding-right: $padding_normal;
    flex: 1 1 auto;
}

.aw-occmgmt-overridenPropertyRendererPanel {
    .aw-occmgmt-oldText {
        padding-left: $padding_normal;
    }
}

.aw-occmgmt-whereUsedtoggle {
    align-items: end
}

.aw-occmgmt-whereUsedtrevrulelabel{
    padding-top: 5px;
}

.aw-occmgmt-FullScreenSeperator{
    padding-left: $padding_normal;
    padding-right: $padding_normal;
}

.aw-occmgmt-FullScreenHeaderContent{
    padding-top: rem-calc(2);
}

.aw-occmgmt-unitGroupEffTable {
    .aw-splm-tableCellTop {
        border: 1px solid;
    }
}

/*
Styling definitions for Element Properties section on add child/sibling panel.
With default class sw-section-content, fields in this section are not laid out properly on UI.
Styles applied on AwXrt & AwWdiget are different. so, need style adjustment for alignment.
Need to override sw-section-content in 'aw-occmgmt-elementPropPanel'
*/
.aw-layout-panel .aw-occmgmt-elementPropPanel .aw-panelSection,
.aw-layout-panel .aw-occmgmt-elementPropPanel .sw-section-content {
    margin: rem-calc(0);
    margin-block-start: $margin_xsmall;
}
