/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

@import 'mixins/mixins';


.aw-subset-panel-padding{
    padding-top:  $padding_large;
}

.aw-subset-spatial{
    padding-top:  rem-calc(30);
    margin-left: $margin_normal;
}

.aw-subset-spatialFilterInBetweenSpace {
    padding-bottom: $padding_normal;
}

.aw-proximity-selectIcon {
    width: rem-calc(12);
    height: rem-calc(12);
    display: inline-block;
    margin-right: $margin_small;
}

.sw-button-iconContainer.aw-proximity-missingTargetInfo {
    cursor: default;
}

