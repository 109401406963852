/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
/* Snapshot Gallery default styling */

.aw-snapshotgallery-searchCriteriaList{
    min-width:150px;
    width:200px;
}

.aw-snapshotgallery-searchCriteriaList {
    .propertyLabelTopContainer  {
        .aw-widgets-propertyLabelTop {
            visibility: hidden;
        }
    }
}

.aw-snapshotgallery-searchPanel{
    margin: 0 0 10px 16px;
}

.aw-snapshotgallery-searchPanel .aw-search-inContentSearchBox{
    padding-bottom: 0;
    padding-top: 15px;
}

.aw-snapshotgallery-searchPanel .aw-widgets-spaceBetween{
    margin-bottom: 3px;
}

.aw-snapshot-height{
    height: rem-calc(24);
    width: rem-calc(24);
    margin-right: $margin_large;
}

.aw-snapshot-target-label {
    margin-top: rem-calc(4);
}

.aw-snapshot-target-section{
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(12);
}

.aw-snapshot-filter{
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(16);
}

.aw-snapshot-margin {
    margin-bottom: 0;
}

.aw-xrt-separator.aw-snapshot-seperator{
    padding-bottom: 0;
    margin:0
}

.aw-viewersnapshot-snapshotNameContainer{
    height:3rem;
    margin:0
}

.aw-viewersnapshot-snapshotPanel .aw-widgets-cellListItemContainer .sw-pic-container,
.aw-viewersnapshot-snapshotPanel .aw-widgets-cellListItemContainer .sw-pic-container .sw-pic{
    margin:0;
    height:rem-calc(130);
}

.aw-viewersnapshot-snapshotPanelSourceLabel {
    padding-top: rem-calc(12);
    margin-bottom: rem-calc(12);
}

.aw-viewersnapshot-sessionSnapshotLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: rem-calc(12);
}

.aw-viewersnapshot-emptyCenter {
    margin: auto;
    width: 50%;
}

.aw-viewersnapshot-emptyText {   
    padding: $padding_large;
    text-align: center;
    font-weight: 300;
    font-size: $fontsize_x_large;
}

.aw-viewersnapshot-emptyText .aw-viewersnapshot-emptyTextBold {
    font-weight: bold;
}

.aw-viewersnapshot-emptyText .aw-viewersnapshot-emptyTextItalic {
    font-style: italic;
}

.aw-viewersnapshot-emptyIcon {
    display: flex;
    justify-content: center;    
}

.aw-viewersnapshot-emptyIcon svg {   
    height: rem-calc(120);
    width: rem-calc(120);
    opacity:0.2;
}