/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2017.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
// mixins
@import 'mixins/mixins';

.aw-occmgmtjs-variantRule ul> :first-child {
    font-style: italic;
}

.aw-occmgmtjs-variantRule ul {
    font-style: normal;
}

.aw-occmgmtjs-newVariantRule {
    font-style: italic;
}

.aw-occmgmt-noDataFound {
    font-style: italic;
}

aw-sublocation-body aw-data-navigator {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    overflow: hidden;
}

.aw-occmgmtjs-stale {
    font-style: italic;
}

.aw-widgets-partialVisibility {
    opacity: 0.2;
}

.aw-occmgmt-effectivity-popup {
    display: flow-root;
}

.aw-occmgmt-tree .sw-guidanceMessage .sw-guidanceMsg-container {
    width : auto
}

aw-splm-table .aw-splm-table .aw-occmgmtjs-overrideIcon {
    .aw-visual-indicator-occmgmt {
        position: absolute;
        left: 50%;
    }
}

aw-splm-table .aw-splm-table .aw-occmgmt-iconMinWidth {
    .aw-visual-indicator-occmgmt {
        min-width: 16px;
    }
}

.aw-occmgmt-propertyIndicator {
    min-width: 16px;
}

.aw-commands-showIconLabel .aw-occmgmt-expand aw-command-bar:not(.aw-layout-userSessionCommands).aw-commands-commandBarVertical aw-command .aw-commands-commandIconButton {
    width: 100%;
}

.aw-occmgmt-expand {
    aw-command-bar:not(.aw-layout-userSessionCommands).aw-commands-commandBarVertical {
        width: auto;

        .aw-commands-wrapper {
            padding: 0;
        }

        aw-command {
            display: block;

            .aw-commands-commandIconButton {
                width: 100%;
                text-align: left;
                height: auto;
                padding: 7px;

                .aw-commandIcon {
                    display: inline-block;
                    vertical-align: middle;

                    .aw-commands-svg {
                        margin: 0;
                    }
                }

                .aw-commands-showIconLabel.aw-commands-commandIconButtonText {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 0.75rem;
                    width: calc(100% - 2rem);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-left: 0.25rem;
                    line-height: normal;
                }
            }
        }
    }

    .aw-widgets-propertyLabelTop {
        font-weight: inherit;
    }
}

.aw-occmgmt-expandToLevelInput {
        padding: $padding_large;
        margin: 0;
    }

.aw-occmgmt-expand-popup{
    .aw-popup-command-bar{
        .aw-widgets-cellListItem{
            margin: 0;
        }
    }
    .sw-errorFlag{
        .sw-property-name{
            &::before {
                margin-left: 12px;
            }
        }

    }
}

.aw-occmgmt-duplicateEditableIndicator {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 16px;
}

.aw-occmgmt-labelAlignmentInTooltips.sw-property.sw-row {
    .sw-property-name {
        width: 10%;
        min-width: fit-content;
    }
    .sw-property-val {
        width: 90%;
        min-width: fit-content;
    }
}
.aw-occmgmt-separatorAlignmentInTooltip.aw-xrt-separator {
    padding-bottom: 0;
}
