// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-occmgmtduplicate-chipPadding {
    padding-top: $padding_normal;
}

.aw-occmgmtduplicate-buttonMargin {
    margin-right: $padding_normal;
}

.aw-occmgmtduplicate-chipDisplay {
    display: table;
}
