/* Copyright 2019 Siemens Product Lifecycle Management Software Inc. */

.aw-requirements-importPanelBody .aw-widgets-propertyRule:hover {
    background-color: $brand_selection_background_hover;
}

.aw-requirement-importPreviewSWA,
.previewElement {
    .diff-html-added span,
    .diff-html-added h1,
    .diff-html-added h2,
    .diff-html-added h3,
    .diff-html-added h4 {
        background-color: $PL_Green_18;
    }

    .diff-html-removed span,
    .diff-html-removed h1,
    .diff-html-removed h2,
    .diff-html-removed h3,
    .diff-html-removed h3 {
        background-color: $Siemens_Red_18;
    }

    .diff-html-changed {
        background-color: $Siemens_Blue_24;
        border-color: $Siemens_Blue_18;
    }

    .aw-requirements-discardUpdate.diff-html-added,
    diffreport span.diff-html-removed {
        background-color: $Siemens_Red_24;
        border-color: $Siemens_Red_18;
    }

    diffreport span.diff-html-added {
        background-color: $PL_Green_24;
        border-color: $PL_Green_18;
    }

    .aw-requirements-unfreezedReqIndicator{
        border-color: $Status_Gray_Base;
    }
    .aw-requirements-freezedReqIndicator {
        border-color: $Siemens_Yellow_17;
    }

    .aw-requirements-rejectedOverwrittenIndicator{
        border-color: $Siemens_Status_Red;
    }

    .aw-requirements-freezedChangedReqIndicator {
        border-color:  $Siemens_Yellow_11;
    }

    .aw-requirements-frozenToLatestReqIndicator{
        border-color: $Siemens_Blue_17;
    }

    .aw-requirement-legendItem .aw-requirement-legendItemMarker.existing,
    .aw-requirements-discardUpdate.diff-html-removed,
    .aw-requirements-discardUpdate.diff-html-changed {
        background-color: $PL_Black_25;
    }

    .aw-requirement-previewPropertyTable {
        table {
            td {
                border-color: $aw_gray_23;
            }
            .aw-splm-tableHeaderCell span {
                background-color: unset;
            }
        }
    }
}

.aw-requirement-legendItem{
    .aw-requirement-legendItemMarker.modified{
        background-color: $Siemens_Blue_18;
    }

    .aw-requirement-legendItemMarker.removed{
        background-color: $Siemens_Red_18;
    }

    .aw-requirement-legendItemMarker.added{
        background-color: $PL_Green_18;
    }
}
.aw-requirements-compareHistory,
.aw-requirements-conflictCompareReportContent {
    diffreport h3 {
        background-color: $PL_Black_24;
    }
}
