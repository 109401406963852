// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* inbox */

aw-signoff-cell {
    .aw-layout-flexRow {
        align-items: center;
    }
    .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellTitle,
    .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellItemType,
    h3.aw-widgets-cellListCellTitle,
    .aw-widgets-cellListCellItemType,
    .aw-widgets-cellListCellText,
    .aw-widgets-cellListCellProperties,
    .aw-widgets-cellListCellProperties .aw-widgets-propertyLabel,
    .aw-widgets-cellListCellProperties .aw-widgets-propertyValue {
        padding-top: $default_cell_list_item_cell_content_padding_top;
    }
}

aw-signoff-profile-cell {
    .aw-layout-flexRow {
        align-items: center;
    }
}

aw-inbox-cell {
    .aw-layout-flexRow {
        align-items: center;
    }
}
