/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-geography-mainView {
    margin-left:4rem;
    margin-right:4rem;
    margin-bottom:30px;
}

.aw-geography-pagesHeader {
    float:left;
    width:50%;
    padding:20px;
    font-size:1.296rem;
}

.aw-geography-logoImage {
    padding:1rem;
    justify-content:center;
    margin-top:10px;
    float: right;
}

.aw-geographyPicker {
    float:right;
    margin-right:30px;
    margin-bottom:30px;
    margin-left:60px;
    margin-top:30px;
}

.aw-geography-section {
    padding:30px;
}

.aw-geography-cornerRadius {
    border-radius:3px 3px 3px 3px;
}

.aw-geography-listox {
    padding:10px;
}

.aw-confidentiality-section {
    text-align:justify;
    text-justify:inter-word;
    margin-left:30px;
    margin-right:30px;
}

.aw-confidentialityStatementSection {
    padding:10px;
    width:99%;
    font-size:0.75rem;
    min-height:90px;
    max-height:150px;
}

.aw-geography-checkbox {
    padding-top:20px;
}

.aw-geographyFooter-buttons {
    max-width:300px;
    margin-left:14px;
    margin-bottom:25px;
}


