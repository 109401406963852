// Copyright 2020 Siemens Product Lifecycle Management Software Inc.
.aw-cadbomalignment-buttonProperty  {
    display: contents;
}

.aw-cba-clickableCellIcon {
    cursor: pointer;
}

.aw-cba-italicText {
    font-style: italic;
}

.aw-cba-displayLabel {
    font-size: $header_fontsize;
    padding-right: $padding_normal;
    width: max-content;
    padding-top: 0;
}

span.aw-cba-displayLabel {
    padding-top: 0;
}

.aw-cba-displayLabelContainer {
    min-width: max-content;
    padding-top: $padding_xsmall;
}

.aw-cba-locationTitle {
    padding-left: $padding_small;
    display: inline-table;
}

.aw-cadbomalignmentjs-taskbarLocationTitle {
    padding-left: $padding_small;
    line-height: normal;
    padding-top: $padding_normal;
    margin-bottom: $margin_normal;
}

.aw-cba-leftPanel {
    padding: $padding_normal 0 0 $padding_normal;
    flex: 1 1 auto;
}
.aw-cba-rightPanel {
    padding: $padding_normal $padding_normal 0 0;
    flex: 1 1 auto;
}

.aw-cba-headerBreadcrumbContainer {
    height: rem-calc(30);
}

.aw-cba-headerConfigContainer {
    height: rem-calc(30);
    a {
     font-weight: normal;
     font-size: $fontsize_normal;
    }
}

.aw-cba-cbaHeaderPanel{
    height: auto;
}

.aw-cba-showAbsentStructure {
    margin-left: rem-calc(24);
}

.aw-cba-buttonMargin {
    margin-right: $padding_normal;
    margin-left: $padding_normal;
}
