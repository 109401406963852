// Copyright 2021 Siemens Product Lifecycle Management Software Inc.
/* snapshottool */

.aw-viewersnapshot-snapshotPanel ul {
    @include flex-direction(row);
    @include flex-wrap(wrap);
}

.aw-viewersnapshot-snapshotPanel
.aw-widgets-cellListWidget .aw-widgets-cellListItem,
.aw-layout-panelMain .aw-layout-panelBody .aw-viewersnapshot-snapshotPanel
.aw-widgets-cellListWidget .aw-widgets-cellListItem {
    width: 50%;
    max-width: rem-calc(136);
    border-radius: rem-calc(3);
    border-width: rem-calc(1);
    border-style: solid;
    padding: $padding_xsmall;
    margin: $margin_small;
}

.sw-popup .aw-viewersnapshot-snapshotPanel .aw-widgets-cellListWidget .aw-widgets-cellListItem
{
    padding: rem-calc(2);
}

.aw-viewersnapshot-snapshotPanel .aw-widgets-cellListWidget .aw-widgets-cellListItem .aw-widgets-cellListItemCell{
    width: 100%;
    @include flexbox();
    @include flex-direction(column);
}

.aw-viewersnapshot-snapshotPanel .aw-widgets-cellListWidget .aw-widgets-cellListItem .aw-widgets-cellListItemCell .aw-widget-pic,
.aw-viewersnapshot-snapshotPanel .aw-widgets-cellListWidget .aw-widgets-cellListItem .aw-widgets-cellListItemCell .aw-widget-pic img{
    height: rem-calc(128);
}

.aw-viewersnapshot-snapshotPanel .aw-viewersnapshot-snapshotNameContainer label .sw-property-val{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-top: rem-calc(12);
}

.aw-viewersnapshot-snapshotNameContainer .sw-property-name
{
    display:none;
}
