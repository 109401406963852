// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-occmgmtSplit-buttonMargin {
    margin-left: $padding_normal;
}

.aw-occmgmtSplit-taskbarHeight {
    height: 4em;
}
