// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* tcPanels */
.aw-change-addAttachments .aw-tcPanels-addObject .aw-layout-panelHeader,
.aw-change-addAttachments .aw-tcPanels-addObject .aw-layout-panelBody,
.aw-change-addAttachments .aw-tcPanels-addObject .aw-layout-panelFooter {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.aw-change-attachmentsPanel .gwt-Label {
    /* CT 091115 */
    clear: both;
}

.aw-taskbasedpage-leftPanelMergeChanges .aw-taskbasedpage-leftPanel,
.aw-taskbasedpage-rightPanelMergeChanges .aw-taskbasedpage-rightPanel{
    padding: 0
}

.aw-change-verical-align{
    padding-top: $padding_normal;
}

.aw-change-mergeUsageProps{
    padding-top: $padding_xsmall;
}

.aw-change-addAttachmentsHeader .aw-base-tabTitle {
    margin: 4px 0 0 5px;
}

.aw-change-backButton>.aw-base-icon,
.aw-createchange-addButton>.aw-base-icon,
.aw-createchange-removeButton>.aw-base-icon {
    height: 24px;
    width: 24px;
}

.aw-change-backButton,
.aw-createchange-addButton,
.aw-createchange-removeButton {
    padding-top: 5px;
    border: none;
}

.aw-change-clearBoth {
    clear: both;
}

.aw-change-findInChange .aw-search-searchBox {
    width: -webkit-calc(100% - 31px);
    width: -moz-calc(100% - 31px);
    width: calc(100% - 31px);
    display: inline-block;
    vertical-align: top;
    margin-right: -4px;
}

.aw-change-findInChange .aw-search-searchButton {
    height: 30px;
    width: 30px;
    background-size: cover;
    float: none;
    vertical-align: top;
    display: inline-block;
}

.aw-change-changeHistoryContentPanel {
    height: 350px;
    width: 100%;
}

.aw-change-expandCollapeSection {
    font-weight: bold;
}

.aw-change-revisionRuleLabel {
    margin-left: 7px;
    margin-bottom: 10px;
}

.aw-change-backButton,
.aw-createchange-addButton,
.aw-createchange-removeButton {
    box-sizing: border-box;
    padding: 2px 0 3px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    border-image: initial;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    border: 0;
    cursor: pointer;
    margin-left: 0;
    margin-top: 3px;
    margin-right: 4px;
    display: inline;
}

.aw-change-impactedAnalysisAddToImpacted {
    @include align-self(flex-end);
}
.aw-change-relationView-layout {
    width: 1500px
}
.aw-change-impactedAnalysisArrows{
    height: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
}

.aw-change-impactedAnalysisRBArrows{
    height: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
}

.aw-change-impactedAnalysisListBox {
    width: 450px;
}

.aw-change-impactedCandidatesLabel .aw-widgets-propertyContainer .aw-layout-flexRow{
    display: grid;
}

.aw-change-addedEntry {
    font-style: italic;
}

.aw-commands-commandIconButtonForChangeSummary{
    margin-left: $margin_large;
}

/* Merge status styling */
.aw-commands-mergeStatusMultipleValuesForChangeSummary {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
}

.aw-commands-mergeStatusSingleValueForChangeSummary {
    margin-left: 10px;
    justify-content: center;
    @include flexbox();
}

.aw-commands-mergeStatusIconForChangeSummary {
   width: rem-calc(15);
}

.aw-change-mergeDisplayLabel {
    font-size: $header_fontsize;
    padding-right: 0.50rem;
    padding-top: 0.30rem;
}

.aw-change-mergeCandidateDisplayLabel{
    font-size: $header_fontsize;
    padding: $panel_section_title_padding;
    margin: $margin_xsmall;
}

span.aw-change-mergeDisplayLabel {
    padding-top: 0.20rem;
}

.aw-change-mergeChangesHeaderIcon {
    .aw-base-icon {
        height: 48px;
        width: 48px;
        max-height: 48px;
        max-width: 48px;
    }
}

.aw-change-mergeCandidateTileBorder {
    border: 1px solid;
}

.aw-panel .aw-panelBody.aw-change-createChangePanel {
    margin-bottom: 0;
}

.aw-change-mergeCandidatesTile {
    .add {
        margin : $margin_normal;
    }
}

.aw-change-mergeCandidatesTile {
    .remove {
        margin : $margin_normal;
    }
}

.aw-change-mergeCandidatesTile {
    .replace {
        margin : $margin_normal;
    }
}

.aw-change-mergeCandidatesTile {
    .propertychange {
        margin : $margin_normal;
    }
}

.aw-change-mergeButton{
    margin: $margin_normal;
}

.aw-change-mergeAllButton{
    margin-right: rem-calc(22);
}
