// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* revise */

.aw-revise-revise .aw-layout-left,
.aw-revise-revise .aw-layout-right {
    white-space: nowrap;
}

.aw-revise-revise .aw-widgets-requiredIndicator,
.aw-revise-revise .aw-base-button,
.aw-revise-revise .aw-widgets-patternListBox {
    float: left;
}
