/* Copyright 2022 Siemens Product Lifecycle Management Software Inc. */


.aw-viewer-explodeViewToolbar {
    display: flex;
    overflow: hidden;
}

.aw-viewer-explodeViewToolbar >* {
    margin: auto;
    padding: $padding_normal;
}

.aw-viewer-explodeViewSlider {
    min-width: rem-calc(400);
}

