// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
.aw-change-changeSummaryTable {
    @include align-self(auto);
}

.aw-change-changeSummaryTableCellContainer {
    position: relative;
    display: inherit;
    height: inherit;
    padding: 2px;
}

.aw-change-changeSummaryTable li.aw-jswidgets-arrayValueCellListItem {
    list-style: none;
}

.aw-change-changeSummaryTableCellContainer .aw-jswidgets-dataGridCellText {
    display: inline-block;
}

.aw-change-changeSummaryTable .aw-jswidgets-cellTop {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
}

.aw-change-changeSummaryTable .aw-splm-tableRow .ui-grid-cell .aw-jswidgets-tablecell{
    height: inherit;
}

.aw-change-changeSummaryTable .aw-change-changeSummaryTableCellText {
    padding: 2px;
}

.aw-change-changeSummaryTable .aw-change-markupText {
    text-decoration: line-through;
}

.aw-change-progressChevronContainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 -1px;
    font-size: $fontsize_normal;
    margin-top: 8px;
    margin-left: 1px;
    font-weight: normal;
    font-style: normal;
}

.aw-change-progressChevronContainer li:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.aw-change-progressChevronContainer li:first-child::before {
    border-top: 0px solid;
    border-bottom: 0px solid;
}

.aw-change-progressChevronContainer li:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-right-width: 1px;
    border-right-style: solid;
}

.aw-change-progressChevronContainer li:last-child::after {
    border-top: 0px solid;
    border-bottom: 0px solid;
}

.aw-change-progressChevronContainer li {
    position: relative;
    max-width: 96px;
    min-width: 5px;
    align-self: stretch;
    height: 32px;
    border-width: 1px;
    border-style: solid;
    border-right-width: 0;
}

.aw-change-progressChevronContainer li::before {
    position: absolute;
    top: 0;
    left: 0;
    border-left: 6px solid;
    border-top: 16px solid;
    border-bottom: 16px solid;
    content: " ";
}

.aw-change-progressChevronContainer li::after {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    border-left: 6px solid;
    border-top: 16px solid;
    border-bottom: 16px solid;
    width: 0;
    height: 0;
    content: " ";
}

.aw-change-progressContainer .aw-widgets-propertyLabelTopValueContainer {
    padding-right: 32px;
}

.aw-change-headerContext {
    align-self: center
}

.aw-change-progressChevronText {
    padding: 8px 16px 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 16px;
}

.aw-change-changeSummaryViewer {
    width: 100%;
}

@media (min-width: $narrowMode_max_width) {
    .aw-change-ProgressProperty {
        display: none;
    }
}

@media (max-width: $narrowMode_max_width) {
    .aw-change-ProgressChevron {
        display: none;
    }
}

.aw-change-cmMaturityProgressBar {
    margin: auto;
}
