// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

.aw-viewerAdmin-healthLoadProgress {
    position: absolute;
    z-index: 9999;
    top: 60%;
    left: 45%;
    width: 40px;
    height: 40px;
    border-radius: 10%;
    background: url(image/miscSessionProgressNeutral32.svg);
    animation: spin 1s infinite linear;
}

.aw-viewerAdmin-nodeExpandUpTargetSvg,
.aw-viewerAdmin-nodeExpandDownTargetSvg {
    cursor: pointer;
    fill-opacity: 0;
}

.aw-viewerAdmin-nodeNormalBorderStyleSvg {
    stroke-width: 1;
}

.aw-viewerAdmin-nodeOutDegreeIntermediateSvg,
.aw-viewerAdmin-nodeOutDegreeSvg {
    font-size: $fontsize_small;
    text-anchor: end;
}

.aw-viewerAdmin-nodeExpandUpSvg,
.aw-viewerAdmin-nodeExpandUpIntermediateSvg,
.aw-viewerAdmin-nodeExpandDownSvg,
.aw-viewerAdmin-nodeExpandDownIntermediateSvg,
.aw-viewerAdmin-nodeCollapseDownSvg,
.aw-viewerAdmin-nodeCollapseDownIntermediateSvg {
    cursor: pointer;
}

.aw-viewerAdmin-nodeTypeColor {
    fill-opacity: 0;
    stroke-width: 1;
}

.aw-viewerAdmin-assignerInfo {
    white-space: pre-line;
}
