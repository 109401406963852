// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

.aw-occmgmtConfigurationBaseline-chipPadding {
    padding: $padding_normal;
    padding-left: $padding_small;

    .aw-widgets-chip {
        margin-right: rem-calc(12);

        &:last-child {
            margin-right: rem-calc(18);
        }
    }
}

.aw-configurationBaseline-headerTitle {
    padding-left: $padding_xlarge;
}

