/* Copyright 2019 Siemens Product Lifecycle Management Software Inc. */

.aw-richtexteditor-qualityMatchedPath {
    background-color: $PL_Green_24;
    outline: 1px solid $PL_Green_18;
}

.aw-richtexteditor-qualityRestOfShortestPath {
    background-color: $Siemens_Red_24;
    outline: 1px dashed $Siemens_Red_18;
}

.aw-relationshipmatrix-mainSvg .highlight {
    fill: $brand_selection_background_hover;
}

a span {
    color: $Siemens_Blue_7 !important;
}

.ck-markerGray {
    background-color: $aw_gray_23;
}

.aw-relationshipmatrix-mainSvg .highlightSelected {
    fill: $brand_selection_background_selected;
}

.aw-relationshipmatrix-cell .heated {
    fill: $aw_ns_red;
}

.aw-relationshipmatrix-cell .inactive {
    fill: $brand_primary_border;
}

.aw-relationshipmatrix-cell .selected-cell {
    fill: $brand_selection_background_selected;
}

.aw-relationshipmatrix-mainSvg {
    stroke: $brand_primary_border;
    fill: $brand_primary_content_background;
}

.aw-relationshipmatrix-link {
    stroke: $brand_primary_link;
    fill: $brand_primary_link;
}

.aw-richtexteditor-tracelinkDashboard .col-title {
    border-color: $brand_primary_border;
}

.aw-richtexteditor-editorSidebar {
    background: $PL_Black_26;
    border-color: $brand_secondary_box_shadow;

    div.ck-thread-top-bar {
        border-color: $Siemens_Yellow_20;
    }
}

.aw-richtexteditor-aceEditorPanel .aw-richtexteditor-editorContainer:not(:has(.ck-editor__main)),
.aw-richtexteditor-aceEditorPanel .ck.ck-editor__main .ck.ck-content:not(:has(.aw-requirement-content)),
.aw-richtexteditor-aceEditorPanel .ck.ck-editor__main .ck.ck-content:empty {
    background-image:
        linear-gradient(90deg, rgba($brand_secondary_content_background, 0), rgba($brand_secondary_content_background, 0.50) 50%, rgba($brand_secondary_content_background, 0) 100%),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0), /* Sidebar icons */
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 32px, transparent 0), /* Heading */
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0), /* Content text */
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0),
        linear-gradient(rgba($PL_Black, 0.1) 16px, transparent 0);
}
