// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
.aw-occmgmt-effectivityUnitLabel  {
    font-weight: $label_text_font_weight;
}

aw-occmgmt-cell {
    .aw-layout-flexRow {
        -webkit-align-items: center;
        align-items: center;
    }

    .aw-widgets-cellListCellImage {
        float: left;
        margin: $default_cell_list_item_cell_image_margin;
    }
}

aw-showobject {
    flex-direction: column;
    width: 100%;
}
