/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
aw-splm-table .aw-splm-tableCellTop.aw-structurecompare-propertydiff {
    width: 100%;
    height: 100%;
    margin: 0;
}

.aw-comparesplitviewjs-revrule.aw-widgets-propertyValContainer.aw-base-small.ng-binding {
    padding-left: 1rem;
}

.aw-comparesplitviewjs-checkboxLegend.sw-component{
    border-left: solid;
    border-left-width: rem-calc(4);
    padding-left: $padding_small;
}

details.aw-compareResults-source.sw-section{
    max-height: 70%;
}

details.aw-compareResults-target.sw-section{
    max-height: 70%;
}


.aw-noResult-section{
    padding-bottom: rem-calc(5);
}
