// Copyright 2019 Siemens Product Lifecycle Management Software Inc.
/* geometric tool */

.aw-geometrictool-dropDownListBackgroundColor {
    background-color: $brand_primary_content_background;
    border-color: $brand_primary_border;
}

.aw-geometrictool-dropDownListShadow {
    -moz-box-shadow: $drop_shadow_large;
    -webkit-box-shadow: $drop_shadow_large;
    box-shadow: $drop_shadow_large;
}

.aw-geometrictool-optionsItemList li:hover {
    background-color: $brand_selection_background_hover;
}