/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-revRule-clauseIconSize .aw-layout-collapsiblePanelSectionTitle .aw-layout-panelSectionTitleChevron svg {
    width: auto;
}

.aw-revRule-removeSpace .aw-layout-panelSectionContent {
    display: none;
}

.aw-revRule-disableMouseClick .aw-revRule-clauseIconSize:not([open]) {
    pointer-events: none;
    opacity: 0.5;
}

.aw-revRule-disableMouseClick .aw-revRule-clauseIconSize[open] .sw-column.sw-panel-summary {
    pointer-events: none;
}

.aw-commands-showIconLabel .aw-layout-declarativePanelRoot.aw-layout-panel .aw-layout-panelSection aw-command-bar:not(.aw-layout-userSessionCommands).aw-commands-commandBarHorizontal.aw-revRule-removeShadow {
    box-shadow: none;
}

.aw-revRule-similarClauseErrorIcon {

    width: 20px;
    height: 20px;
    float: left;
    vertical-align: middle;
    margin-left: 11px;
}

.aw-revRule-alignSimilarClauseErrorContent {
    margin-left: 15px;
    margin-right: 15px;
}

.aw-revRule-alignNoArgumentsLabel {
    text-align: center;
}

.aw-layout-panel .aw-base-scrollPanel .aw-revRule-clauseIconSize{
    margin-bottom: $margin_normal;
}