// Copyright 2020 Siemens Product Lifecycle Management Software Inc.
/* begin requirementsmanager */

.aw-requirementsmanager-tiles div.aw-tile-canvas .aw-tile-mainContainer{
    margin: unset;
}

.aw-requirementsmanager-tiles div.aw-tile-tileGroup {
    padding: unset;
    max-width: unset;
}

.aw-requirementsmanager-tiles div.aw-flex-row{
    margin-top: 0;
}

.aw-requirementsmanager-tiles{
    height: 18vh;
}

.aw-splm-tableCellTop div.aw-requirementsmanager-summaryTableContent{
    float:left;
    margin-left: auto;
    margin-right: auto;
    overflow-y: hidden;
}

.aw-splm-tableCellTop .aw-requirementsmanager-summaryTableIcon {
    padding: 0;
}


.aw-requirementsmanager-summaryTableIcon.aw-base-icon {
    width: $icon_button_width;
    height: $icon_button_height;
}


.aw-requirementsmanager-chartDivison {
    display: flex;
    width: 100%;
}

div.aw-chart.aw-requirementsmanager-chart {
    margin: $margin_large;
    width: calc(100% / 3 - 32px);
}

div.aw-chart.aw-requirementsmanager-chart text.highcharts-title tspan {
    font-weight: bold;
}

.aw-requirementsmanager-contextSection{
    height: calc(100vh - 270px);
    padding: $padding_normal $padding_normal 0 $padding_normal;
}

.aw-requirementsmanager-chartSection{
    height: 33vh;
}

.aw-requirementsmanager-tableSection{
    height: 24vh;
}

.aw-requirementsmanager-tracelinkLabel {
    cursor: pointer;
    padding-right: $padding_small;
}

.aw-requirementsmanager-dashboard label.aw-widgets-propertyLabel{
    padding-left: rem-calc(15);
    padding-top: $padding_normal;
}

