// Copyright 2018 Siemens Product Lifecycle Management Software Inc.
/* begin com.siemens.splm.client.workflow */

.aw-workflow-newWorkflowButtonDisabled {
    cursor: default;
}

.aw-workflow-statusLabel {
    font-weight: 600;
    font-size: $fontsize_small;
}

.aw-workflow-signoffsView {
    height: 95%;
}

.aw-workflow-newWorkflowProcess input[type="radio"] {
    width: auto;
}
.aw-workflow-userPanelClearAll .aw-base-normal>a {
    margin-left: 280px;
}

.aw-workflow-newWorkflowProcess .aw-workflow-radioButtonText {
    padding-right: 13px;
}

.aw-workflow-userSelection,
.aw-workflow-userListPanel {
    height: inherit;
}

.aw-workflow-userSelection>.aw-workflow-userListPanel {
    float: left;
}

.aw-workflow-searchBox {
    height: 29.000px;
    padding: 0;
    width: 100%;
    outline: none;
    margin-bottom: 0;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.aw-workflow-topPanel {
    height: 100%;
    width: 100%;
}

.aw-workflow-mainPanel {
    height: inherit;
    width: 100%;
}

.aw-workflow-backButton {
    float: left;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0;
    margin-top: 3px;
    margin-right: 10px;
    padding: 0;
}

.aw-workflow-users .aw-workflow-backButton {
    margin-top: 0;
}


.aw-workflow-userSelection>h3.aw-widgets-cellListCellTitle {
    cursor: default;
}

.aw-workflow-projectSelection {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

.aw-workflow-projectListLabel {
    float: left;
    display: inline;
    padding: 0 7px 7px 0;
    font-size: $fontsize_small;
    font-weight: 600;
}

.aw-workflow-requiredParticipantText {
    font-style: normal;
    font-size: $fontsize_normal;
    font-weight: normal;
}

.aw-workflow-requiredParticipantTextContainer {
    padding-top: 10px;
    height: 20px;
    font-size: $fontsize_large;
}

.aw-workflow-parentProcessLink {
    position: relative;
    z-index: 9999;
}

.aw-workflow-openProcessSvg {
    cursor: pointer;
}

.aw-workflow-requiredParticpantTile .aw-widgets-cellListWidget {
    width: 100%;
}

.aw-workflow-label {
    font-size: $fontsize_large;
}

.aw-workflow-panelSectionTitle .aw-layout-panelSectionTitle {
    font-size: $fontsize_normal;
    font-weight: 700;
    text-transform: uppercase;
}

.aw-workflow-targetSectionRow {
    max-height: 200px;
    min-height: 90px;
    height: 160px;
}

.aw-panel .aw-workflow-performTaskPanel {

    .aw-panelBody  {
        padding-left: 0;
        padding-right: 0;
    }

    .sw-section-content .aw-layout-panelBody   {
        padding-left: 0;
        padding-right: 0;
    }

    .sw-section .sw-section-content {
        padding-left: 0;
        padding-right: 0;
    }

    .aw-layout-panelFooter  {
        padding-left: 0;
        padding-right: 0;
    }
}

