/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2020.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-actionReviseColor {
    background-color: $Status_Yellow_9;
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-actionOverwriteColor {
    background-color: $Status_Red_8;
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-actionNewColor {
    background-color: $Status_Green_8;
}

.aw-splm-table .aw-splm-tableCellTop.aw-importexport-useExistingValue {
    color: $PL_Black_12;
}
