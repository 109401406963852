/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2018.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-relbrowser-partialOpacity div.sw-cell-image {
    opacity: 0.4;
}

.aw-relations-nodeSvg .aw-base-small {
    font-size: $fontsize_large;
}


.aw-relbrowser-defaultCell  div.sw-cell-image {
    cursor: pointer;
    overflow: hidden;
    border: 1px solid;
}

.aw-relbrowser-defaultCell  div.sw-cell-image:hover,
.aw-relbrowser-defaultCell  div.sw-cell-image:active {
    border: 2px solid;
}

