/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2019.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/
.aw-taskbasedpage-leftPanel {
    padding: 8px 0 8px 16px
}
.aw-taskbasedpage-rightPanel {
    padding: 8px 16px 8px 0
}
.aw-taskbasedpage-headerTitleSeparator {
    padding-left: 8px;
    padding-right: 8px
}
.aw-taskbasedpage-taskbarTitle{
    font-size: $default_font_size;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 2px
}
.aw-taskbasedpage-taskbarSubTitle{
    font-size: $fontsize_normal;
    font-weight: 600;
    line-height: 16px;
}
.aw-taskbasedpage-taskBarHeader{
    padding-top: 2px;
    padding-bottom: 6px
}

