// Copyright 2019 Siemens Product Lifecycle Management Software Inc.
.aw-occmgmt-contextOverrideHeaderLabel {
    color: $Siemens_Yellow_10;
}

.aw-occmgmt-duplicateEditableIndicator,
aw-splm-table .aw-occmgmt-duplicateEditableIndicator {
    border-color: $aw_gray_23;
}

.aw-widgets-cellListItem {
    .aw-markup-added * {
        color: $brand_markup_added_color;
    }

    .aw-markup-deleted {

        a,
        * {
            text-decoration-color: $brand_markup_deleted_color;
        }
    }
}


.aw-grid-markup-deleted {

    a,
    * {
        text-decoration-color: $brand_markup_deleted_color;
    }
}

.aw-occmgmt-split {
    width: 100%;
    .aw-layout-breadCrumbContainer {
        .sw-column {
            .aw-widgets-primaryCrumb {
                color: $main_text_color;
            }

            .aw-widgets-selectedCrumb {
                color: $main_text_color;
            }

            .aw-widgets-crumbLink {
                color: $main_text_color;
                &:hover {
                    color: $link_main_hover;
                }
            }
        }
    }
}

.aw-occmgmt-cellImageTooltip,
.aw-occmgmt-cellImageTooltip:hover {
    border-color: $Siemens_Blue_12;
}

.aw-splm-table .ui-grid-row.aw-row-icon.aw-occmgmtjs-partialSelection {
    background: $Siemens_Blue_10_Old;
}
aw-splm-table .aw-splm-table .aw-occmgmtjs-overrideIcon {
    .aw-splm-tableCellText:empty{
        background-color: inherit;
    }

}
