/*
   @<COPYRIGHT>@
   ==================================================
   Copyright 2016.
   Siemens Product Lifecycle Management Software Inc.
   All Rights Reserved.
   ==================================================
   @<COPYRIGHT>@
*/

.aw-confidentialityAgreement-sectionTitle {
    margin-top:30px;
}

.aw-confidentialityAgreement-statementSection {
    padding:10px;
    width:99%;
    font-size:0.75rem;
    min-height:90px;
    max-height: 300px;
}


